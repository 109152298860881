import * as React from 'react'
import DataTable from 'react-data-table-component';
import {EditFormBase} from '../../forms';
import Papa from 'papaparse';

import Page from '../../components/Page'
import Container from '../../components/Container'
import IndexLayout from '../../layouts'
import * as Api from '../../api/api';
import {Col} from "../../components/Col";
import {Row} from "../../components/Row";
import {getAuthToken, setAuthToken} from "../../authToken";

function parseRow(input: string[]): Api.DirectAlertTableEntity {
	return {
		accountName: input[1],
		accountNumber: input[0],
		zipCode: input[2]
	};
}

interface IState {
  data: Api.ApiRegistrations;
  isLoading: boolean;
  importCsv: string;
  authToken: string|null;
}

class AdminIndex extends React.Component<{}, IState & Api.SearchQuery> {
	constructor(props, context) {
		super(props, context);
		this.state = {
			data: {
				registrations: [],
				recordsAffected: 0,
				success: false,
			},
			isLoading: true,
			query: '',
			skip: 0,
			take: 100,
			importCsv: '',
      authToken: getAuthToken()
		};
	}

	public componentDidMount() {
		this.setState({
			isLoading: true
		}, () => {
			new Api.DirectAlertApi().directAlertAdminSearchPOST({
				req: {
					query: this.state.query || '',
					skip: this.state.skip || 0,
					take: this.state.take || 100,
				},
        authToken: getAuthToken() || undefined,
			}).then((res) => {
				if (res.value) {
					this.setState({data: res.value || {}, isLoading: false});
				}
				else {
					console.log('no res.value', res);
				}
			}, (err) => {
				console.log('Failed to load', err);
				this.setState({isLoading: false});
			});
		});
	}

	public submitSearch(e) {
		e.preventDefault();
		this.componentDidMount();
	}

	public readFile(e) {
		const fileList: FileList = e.currentTarget.files;
		console.log(fileList);
		for (let i = 0; i < fileList.length; i = i + 1) {
			console.log(`reading ${fileList[i].name}`);
			const pFile = fileList[i];
			Papa.parse(pFile, {
				complete: (results, file) => {
					console.log("Parsing complete:", results, file);
					const data = Object.assign({}, this.state.data);
					data.registrations = results.data
						.map((row: string[]) => parseRow(row))
						.filter((row) => (row.zipCode || '').length === 5);
					this.setState({data, importCsv: 'y'});
				}
			});
		}
	}

	public doImport(e) {
		e.preventDefault();
		this.setState({isLoading: true}, () => {
			new Api.DirectAlertApi().directAlertAdminImportPOST({
				req: {
					registrations: this.state.data.registrations,
				},
        authToken: getAuthToken() || undefined,
			}).then((result) => {
				console.log('Import complete', result);
				this.setState({data: {registrations: []}, importCsv: ''}, () => this.componentDidMount());
			}, (error) => {
				alert(error);
				this.setState({isLoading: false});
			})
		})
	}

	public render() {
		const isImport = this.state.importCsv && this.state.importCsv.length > 0;
		const authTokenOk = (this.state.authToken || '').length === 36;
		return (
			<IndexLayout>
				<Page>
					<Container>
            <p>
              <input
                type={"text"}
                value={this.state.authToken || ''}
                placeholder="Paste API Key Here"
                onChange={(e) =>
                  this.setState({authToken: setAuthToken(e.currentTarget.value)})}
              />
              {authTokenOk && <span style={{color: 'olive'}}>OK!</span>}
            </p>
            {authTokenOk && <React.Fragment>
              <h1>{isImport ? 'Import' : 'Manage'} DirectAlert Users</h1>
              <Row>
                <Col sm={6}>
                {!isImport && <form onSubmit={(e) => this.submitSearch(e)}>
                  Account Name/#/ZIP
                  {EditFormBase.boundTextboxValue(
                    '',
                    this.state.query || '',
                    (e) => this.setState({query: e.toUpperCase()}),
                    '',
                    false,
                    this.state.isLoading)
                  }
                  <button type="submit">Search</button>
                </form>}
                </Col>
                <Col sm={6}>
                  <input id="upload" type="file"
                       onChange={(event)=> {
                         this.readFile(event)
                       }}
                       onClick={(event)=> {
                         event.currentTarget.value = ''
                       }}

                  />
                </Col>
              </Row>

              {this.state.isLoading && <div>
                Please wait...
              </div>}

              {this.state.data.registrations && (
                <div>
                  {isImport && <p>
                    About to import {this.state.data.registrations.length} records
                    <button type="submit" disabled={this.state.isLoading} onClick={(e) => this.doImport(e)}>Submit</button>
                  </p>}
                  {this.renderRegistrationsTable()}
                </div>
              )}
            </React.Fragment>}
					</Container>
				</Page>
			</IndexLayout>
		);
	}

  private renderRegistrationsTable() {
    if (this.state.isLoading) {
      return (
        <div>Loading...</div>
      );
    }
    return (
      <DataTable
        columns={[
          {
            sortable: false,
            name: 'Name',
            selector: 'accountName'
          }, {
            sortable: false,
            name: 'Account #',
            selector: 'accountNumber'
          }, {
            sortable: false,
            name: 'ZIP',
            selector: 'zipCode'
          }, {
            sortable: false,
            name: 'Date',
            selector: 'cDate'
          }
        ]}
        data={this.state.data.registrations.splice(0, 100)}
        pagination
      />
    );
  }
}

export default AdminIndex
