export function getAuthToken(): string | null {
  if (typeof localStorage !== 'undefined') {
    return localStorage.getItem('authToken') || null;
  }
  return null;
}
export function setAuthToken(authToken: string): string {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem('authToken', authToken.trim());
  }
  return authToken.trim();
}
